<template>
  <div class="approval-history">
    <b-col>
      <div v-for="(history, index) in histories" :key="index">
        <b-row>
          <b-col md="3">
            <label class="lable-sp">Hành động: </label>
          </b-col>
          <b-col md="9">
            <label>{{ history.actionName }}</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <label class="lable-sp">Chỉnh sửa bởi: </label>
          </b-col>
          <b-col md="9">
            <label>{{ history.employeeName }}</label>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col md="3">
            <label class="lable-sp">Chỉnh sửa lúc: </label>
          </b-col>
          <b-col md="9">
            <label>{{ history.createdAt }}</label>
          </b-col>
        </b-row>
        <b-table
          bordered
          hover
          :fields="historyDetailFields"
          :items="history.diffValues"
        >
        </b-table>
        <hr class="hr-text mb-4" data-content="" style="font-weight: 600" />
      </div>
    </b-col>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service';
import { makeToastFaile } from '@/utils/common';

export default {
  props: ['approvalId'],
  data() {
    return {
      historyDetailFields: [
        {
          key: 'keyName',
          label: 'Thông tin',
          thStyle: { textAlign: 'center', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'originalValue',
          label: 'Trước',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
        {
          key: 'newValue',
          label: 'Sau',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
      ],
      histories: [],
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      this.histories = [];
      ApiService.get(`v2/approval-histories/by-approval/${this.approvalId}`)
        .then(({ data }) => {
          if (data.status === 200) {
            this.histories = data.data;
          } else {
            makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(() => {
          makeToastFaile('Lỗi');
        });
    },
  },
};
</script>

<style lang="scss">
.approval-history {
  .lable-sp {
    font-weight: 500;
  }
}
</style>
